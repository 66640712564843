.detEntrega-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cancel img {
  width: 150px;
  height: 150px;
  stroke-width: 0.25rem;
}

.detEntrega-content .detEntrega-content-top {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-bottom: 16px;
}

.detEntrega-content .detEntrega-content-top img:nth-child(1) {
  margin-left: 20%;
}

.detEntrega-content .detEntrega-content-top-retirada {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-bottom: 16px;
}

.detEntrega-content .detEntrega-content-top-retirada img:nth-child(1) {
  margin-left: 5%;
}

.detEntrega-content .detEntrega-content-top-retirada img:nth-child(2) {
  display: none;
}

.detEntrega-content .detEntrega-content-middle {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  padding: 0 16px;
}

.detEntrega-content .detEntrega-content-middle img {
  width: 110px;
  height: 110px;
  stroke-width: 0.25rem;
}

.detEntrega-content .detEntrega-content-middle .detEntrega-status-pedido {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.detEntrega-content .detEntrega-content-middle .content-retirada {
  width: 110px;
  height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.detEntrega-content .detEntrega-content-middle .legenda-retirada {
  font-size: 14px;
  font-weight: 400;
  color: var(--yellow-abra);
  text-transform: uppercase;
  text-align: center;
}

.detEntrega-content .detEntrega-content-middle .legenda-retirada-indisponivel {
  font-size: 14px;
  font-weight: 400;
  color: var(--text-color-light);
  text-align: center;
}

.detEntrega-content .detEntrega-content-middle .legenda-retirada-previa {
  font-size: 14px;
  font-weight: 400;
  color: var(--text-color-light);
  text-transform: uppercase;
  text-align: center;
}

.detEntrega-content .detEntrega-content-middle .icon-retirada {
  width: 40px;
  height: 40px;
  margin-bottom: 5px;
}

.detEntrega-content .detEntrega-content-bottom,
.detEntrega-content .detEntrega-content-bottom-retirada {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 16px;
  margin-bottom: 36px;
}

.detEntrega-content .detEntrega-content-bottom,
.detEntrega-content .detEntrega-content-bottom-retirada img {
  transform: scaleY(-1);
}

.detEntrega-content .detEntrega-content-bottom img:nth-child(1) {
  margin-left: -150px;
}

.detEntrega-content .detEntrega-content-bottom img:nth-child(2) {
  margin-left: -300px;
}

.detEntrega-content .detEntrega-content-middle .detEntrega-status-pedido.status-coleta {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 115px;
  height: 110px;
  margin-top: 2px;
}

.detEntrega-content .detEntrega-content-middle .detEntrega-status-pedido .legenda-coleta {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: var(--text-color-light);
  text-transform: uppercase;
}

.detEntrega-content .detEntrega-content-middle .detEntrega-status-pedido .legenda-coletado-incompleto {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: var(--yellow-abra);
  text-transform: uppercase;
}

.detEntrega-content .detEntrega-content-middle .detEntrega-status-pedido .legenda-coletado-completo {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #BA0E4A;
  text-transform: uppercase;
}

.detEntrega-content .detEntrega-content-middle .detEntrega-status-pedido .legenda-coleta-indisponivel {
  text-align: center;
  color: var(--text-color-light);
  font-size: 14px;
  text-transform: uppercase;
}

.detEntrega-content .detEntrega-content-middle .icon-entrega {
  width: 50px;
  height: 50px;
}

.detEntrega-content .detEntrega-dots-up {
  position: relative;
  bottom: 3rem;;
}

.detEntrega-content .detEntrega-dots-down {
  position: relative;
  rotate: 180deg;
  top: 4rem;
}

.detEntrega-content .div-detEntrega-coletado {
  height: 110px;
  width: 110px;
  justify-content: center !important;
  padding-top: 2px;
}

.detEntrega-dots {
  display: none;
}

@media Screen and (max-width: 1024px) {
  .detEntrega-content .detEntrega-content-middle {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .detEntrega-content .detEntrega-content-top,
  .detEntrega-content .detEntrega-content-bottom,
  .detEntrega-content .detEntrega-content-top-retirada,
  .detEntrega-content .detEntrega-content-bottom-retirada {
    display: none;
  }

  .detgrid-footer .detgrid-footer-content-right {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding-top: .5rem;
  }

  .detgrid-nfe, .detgrid-transportadora {
    width: fit-content;
  }

  .detgrid-footer .detgrid-footer-content-right .detgrid-transportadora {
    margin-top: 1rem;
    align-items: center;
  }

  .MuiIconButton-label {
    position: relative;
    left: 2%;
  }

  .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded {
    flex-direction: column-reverse;
  }

  .detEntrega-dots {
    rotate: 90deg;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 110px;
  }

  .dots-img {
    display: none;
  }

  .detEntrega-dots div {
    font-size: 2rem;
    color: var(--pink-abra);
    letter-spacing: 4px;
    margin-bottom: 1rem;
  }

  .detEntrega-content .div-detEntrega-coletado {
    padding: 0;
  }
}