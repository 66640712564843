.page-statuspedidos {
  width: 100vw;
  height: 100%;

  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* align-items: center; */
  padding: 16px;

  background: rgba(237, 237, 237, 0.5);
  overflow-y: auto;
}

.page-statuspedidos h4 {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 15px;
}

.search-content {
  width: 100%;
  margin-bottom: 16px;

  display: flex;
  /* justify-content: center; */
  align-items: center;
  margin-top: 16px;
}

.search-content button {
  margin-left: 16px;
  background-color: var(--yellow-abra);
  color: #fff;
}

.search-content button:hover {
  background-color: var(--yellow-abra);
}

.data-content {
  width: 100;
  height: 100%;
}

#search-pedido {
  width: 130px;
}

#search-dataInicio {
  color: rgb(83, 83, 83);
  text-align: center;
  width: 130px;
}

#search-dataFim {
  color: rgb(83, 83, 83);
  text-align: center;
  width: 130px;
}

#search-statusPedido {
  min-width: 110px;
}

#search-statusItem {
  min-width: 110px;
}

#search-transportadora {
  min-width: 110px;
}