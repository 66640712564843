.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  padding: 10px;
  height: 13vh;
  padding: 0 7rem;
}

.headerContent {
  display: flex;
  align-items: center;
  gap: 5px;
}

.pageContainer {
  max-width: 1200px;
  margin: 0 auto;
  padding: .5rem 2rem;
}

.backButton {
  margin: 0 1rem;
  cursor: pointer;
}

.pageContainer h1 {
  color: #333;
  margin-bottom: 2rem;
}

.listContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media (max-width: 768px) {
  .header {
    padding: 0 1rem;
  }

  .pageContainer {
    padding: 1rem;
  }

  .pageContainer h1 {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }
}