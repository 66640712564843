.itemContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  transition: transform 0.2s ease;
}

.itemContainer:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.itemInfo {
  flex: 1;
}

.mainInfo {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.5rem;
}

.mainInfo h3 {
  margin: 0;
  color: #333;
  font-size: 1.1rem;
}

.tipo {
  background-color: #f0f0f0;
  padding: 0.25rem 0.75rem;
  border-radius: 16px;
  font-size: 0.9rem;
  color: #666;
  font-weight: bold;
}

.details {
  display: flex;
  gap: 1rem;
  font-size: 0.9rem;
  align-items: center;
}

.status {
  padding: 0;
  border-radius: 16px;
  font-weight: 500;
}

.abramais {
  background-color: #fff3cd;
  color: #FBB813;
}

.abracasa {
  background-color: #fff3cd;
  color: #BA0E4A;
}

.fisica {
  background-color: #fff3cd;
  color: #856404;
}

.data {
  color: #666;
}

.button {
  background-color: var(--yellow-abra);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  text-decoration: none;
  font-weight: 500;
  transition: background-color 0.2s ease;
}

.button:hover {
  background-color: #d4a017;
}

@media (max-width: 768px) {
  .itemContainer {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .mainInfo {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }

  .details {
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-start;
  }

  .button {
    width: 100%;
    text-align: center;
  }
} 