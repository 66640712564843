.cabgrid-content{
    display:flex;
    justify-content: space-between;
    align-items: center;
}

.cabgrid-content .cabgrid-left-content{
    width: 90%;
    height: 100%;
    display:flex;
    flex-direction: column;    
}

.cabgrid-content .cabgrid-left-content .cabgrid-desc-item{
    color: var(--text-color-light);
    font-weight: bold;
}
.cabgrid-content .cabgrid-left-content .cabgrid-preco-item{
    font-weight: bold;
    font-size: 14px;
    margin-top: 16px;
}

.cabgrid-content .cabgrid-right-content{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    gap: 1.5rem;
    padding-right: 1rem;
}

.cabgrid-data-entrega-agendada strong,
.cabgrid-data-entrega strong,
.cabgrid-montagem strong,
.cabgrid-retirada strong {
  color: var(--pink-abra);
}

.cabgrid-right-content .cabgrid-data-entrega-agendada,
.cabgrid-right-content .cabgrid-data-entrega,
.cabgrid-right-content .cabgrid-montagem,
.cabgrid-right-content .cabgrid-retirada {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
}

.cabgrid-right-content .cabgrid-montagem,
.cabgrid-right-content .cabgrid-retirada{
  margin-left: 16px;
}

.cabgrid-right-content .cabgrid-data-entrega-agendada{
  margin-right: 16px;
}

.cabgrid-right-content .cabgrid-data-entrega-agendada img,
.cabgrid-right-content .cabgrid-data-entrega img {
  width: 40px;
  height: 40px;
}

.cabgrid-right-content .cabgrid-montagem img {
  width: 30px;
  height: 30px;
}

.cabgrid-arrow-down {
  width: 20px;
  height: 20px;
  margin-right: 20px;
}

@media Screen and (max-width: 1024px) {
  .cabgrid-content {
    flex-direction: column;
  }

  .cabgrid-left-content {
    width: fit-content !important;
    padding: .5rem;
  }

  .cabgrid-desc-item {
    text-align: center;
  }

  .cabgrid-arrow-down {
    display: none;
  }

  .cabgrid-arrow-down-footer {
    display: block;
  }

  .cabgrid-preco-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cabgrid-right-content {
    padding-right: 0 !important;
    justify-content: space-evenly !important;
    padding-top: .5rem;
  }

  .MuiIconButton-edgeEnd {
    margin: 0 !important;
  }

  .detgrid-transportadora {
    width: 100%;
  }

  .detgrid-transportadora span {
    text-align: center;
  }

  .cabgrid-right-content .cabgrid-montagem,
  .cabgrid-right-content .cabgrid-retirada,
  .cabgrid-right-content .cabgrid-data-entrega-agendada {
    margin: 0px;
  }
}