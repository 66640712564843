* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Yantramanav', sans-serif;
}

#root {
    height: 100vh;
    --yellow-abra: #FBB813;
    --pink-abra: #BA0E4A;
    --text-color-light: #808080;    
}

*::-webkit-scrollbar {
    width: 8px;    /* width of the entire scrollbar */
  }  
  
  *::-webkit-scrollbar-thumb {
    background-color: var(--pink-abra);    /* color of the scroll thumb */
    border-radius: 10px;       /* roundness of the scroll thumb */
  }
