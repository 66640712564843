#page-principal {
  width: 100vw;
  height: 100%;

  display: flex;
  justify-content: center;
  /* align-items: center; */

  background: rgba(237, 237, 237, 0.5);
  overflow-y: auto;
}

#page-principal .content-wrapper {
  position: relative;

  width: 85%;
  max-width: 1100px;

  height: 100%;
  max-height: 680px;

  display: block;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
}

#page-principal .content-wrapper .content-loader {
  /* width: 100%; */
  height: 100%;
  margin-top: 16px;
}

#page-principal .content-wrapper .header-logo {
  width: 5rem;
}

#page-principal .content-wrapper #logoCadabra {
  margin-right: 10px;
}

.content-wrapper .header-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content-wrapper .header-content .header-right-content {
  width: 325px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
.content-wrapper .header-content .header-left-content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
}

.content-wrapper .header-content .header-right-content span:nth-child(1) {
  margin-bottom: 5px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 20px;
}

.content-wrapper .header-content .header-right-content span:nth-child(2) {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 14px;
  color: dimgrey;
}

.content-wrapper .infopedido {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.content-wrapper .infopedido .infopedido-left-content {
  height: 100%;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 16px;
}

.content-wrapper .infopedido .infopedido-left-content-architect {
  font-weight: normal;
  font-size: 14px;
}

.content-wrapper .infopedido .infopedido-right-content {
  height: 100%;
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  font-size: 14px;
}

.content-wrapper .infopedido .infopedido-right-content .status-desc {
  font-weight: 500;
  color: var(--pink-abra);
}

.content-wrapper .item-pedido {
  margin-top: 16px;
}

.content-wrapper .content-footer {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-wrapper .content-footer .content-contato p {
  margin-top: 20px;
  text-align: center;
  font-weight: 600;
}

.infopedido-sepator-content {
  display: none;
}

.no-content-detailed {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.no-content-detailed-text {
  margin: 1.5rem 0px;
  font-size: 1rem;
  text-align: center;
  font-weight: 600;
}

.building-content-img {
  width: 25rem;
  margin-bottom: .3rem;
}

.backHome {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .5rem;
  cursor: pointer;
  text-decoration: underline;
}

@media Screen and (max-width: 1024px) {
  #page-principal .content-wrapper {
    width: 100%;
  }

  .content-wrapper .header-content {
    padding: 0 1rem;
  }

  .content-wrapper .header-content .header-right-content,
  .content-wrapper .header-content .header-left-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .content-wrapper .header-content .header-left-content {
    margin-right: 0;
  }

  .content-wrapper .infopedido {
    width: 100%;
    height: 70px;
    margin-top: 0;
    justify-content: space-evenly;
  }

  .content-wrapper .infopedido .infopedido-right-content {
    width: fit-content;
    justify-content: center;
  }
  
  .item-pedido .accordion-content {
    width: 100%;
  }

  *::-webkit-scrollbar {
    width: 8px;    /* width of the entire scrollbar */
  }  
  
  *::-webkit-scrollbar-thumb {
    background-color: var(--pink-abra);    /* color of the scroll thumb */
    border-radius: 10px;       /* roundness of the scroll thumb */
  }

  .item-pedido {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem !important;
    padding-left: 1rem;
    padding-right: .5rem;
  }

  .MuiButtonBase-root.MuiAccordionSummary-root {
    flex-direction: column;
  }
  
  .content-wrapper .header-content .header-right-content {
    width: auto;
    margin-right: 0;
    align-items: center;
    justify-content: space-evenly;
    /* margin-top: 20px; */
  }

  .content-wrapper .header-content .header-right-content span {
    text-align: center;
  }

  .infopedido {
    margin-top: 1rem !important;
    align-items: center;
  }

  .infopedido-left-content {
    width: fit-content !important;
    align-items: center;
  }

  .content-wrapper .infopedido {
    align-items: center;
    gap: .5rem;
  }
  
  .content-wrapper .infopedido .infopedido-left-content {
    padding: 0 !important;
    margin-left: 10px;
    justify-content: center;
  }

  .infopedido-sepator-content {
    display: block;
  }

  .infopedido-sepator-content hr {
    height: 3rem;
    padding: 0.1rem;
    background: var(--pink-abra);
  }

  .content-wrapper .infopedido .infopedido-left-content-architect {
    text-align: center;
  }

  .no-content-detailed-text {
    margin: 1.5rem 0px;
    font-size: 1rem;
    text-align: center;
    font-weight: 600;
  }
  
  .building-content-img {
    width: 20rem;
    margin-bottom: .3rem;
  }
}
