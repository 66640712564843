.detgrid-footer {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
}

.detgrid-footer .detgrid-footer-content-left {
  font-weight: bold;
  font-size: 13px;
}

.detgrid-footer .detgrid-footer-content-left .detgrid-contato p {
  margin-top: 20px;
  text-align: justify;
}

.detgrid-footer .detgrid-footer-content-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
}

.detgrid-footer .detgrid-footer-content-right .detgrid-nfe {
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}

.detgrid-footer .detgrid-footer-content-right .detgrid-nfe img {
  margin-right: 5px;
}

.detgrid-footer .detgrid-footer-content-right .detgrid-nfe a,
.detgrid-footer .detgrid-footer-content-right .detgrid-errorNota span {
  text-decoration: none;
  color: var(--text-color-light);
  font-weight: bold;
  font-size: 14px;

  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.detgrid-footer .detgrid-footer-content-right .detgrid-transportadora {
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  margin-top: 20px;
}

.detgrid-footer .detgrid-footer-content-right .detgrid-transportadora span {
  text-transform: uppercase;
  color: var(--text-color-light);
  font-weight: bold;
  font-size: 16px;
}

.detgrid-footer .detgrid-footer-content-right .detgrid-transportadora p {
  width: 100px;
  font-size: 15px;
}

.detgrid-footer .detgrid-footer-content-right .detgrid-transportadora a {
  color: blue;
}

.detgrid-footer .detgrid-footer-content-right .detgrid-errorNota,
.detgrid-footer .detgrid-footer-content-right .detgrid-searchingNota {
  width: 120%;
  display: flex;
  align-items: center;
  justify-content: center; 
}

.detgrid-footer .detgrid-footer-content-right .detgrid-errorNota span,
.detgrid-footer .detgrid-footer-content-right .detgrid-searchingNota span {
  margin-left: 3px;
}

@media Screen and (max-width: 1024px) {
  .detgrid-nfe {
    width: fit-content !important;
  }

  .detgrid-transportadora p {
    text-align: center;
  }
}