.page {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px;
  height: 13vh;
}

.headerContent {
  display: flex;
  align-items: center;
  gap: 5px;
}

.container {
  width: 100vw;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.containerChildren {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3vh;
}

.logos {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.logosMobile {
  display: none;
}

.divLogo {
  display: flex;
  gap: 5px;

}

.logo {
  width: 10rem;
}

.pipe {
  width: 1px;
  height: 100%;
  background-color: var(--yellow-abra);
  margin-left: .5rem;
}

.informations {
  display: flex;
  flex-direction: column;
}

.welcome {
  font-family: 'Segoe UI', sans-serif;
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 5px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 5rem 2.3rem;
  height: 100%;
}

.firstStep {
  font-family: 'Segoe UI', sans-serif;
}

.step {
  font-weight: 600;
}

.ourStep {
  font-weight: bold;
  color: var(--yellow-abra);
}

.yourStep {
  font-weight: 600;
  color: var(--pink-abra);
}

.inputGroup {
  width: 100%;
}

.input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.captchaContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.button {
  background-color: var(--yellow-abra);
  color: #fff;
  padding: 10px 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.button:hover {
  background-color: var(--yellow-abra);
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    align-items: stretch;
    height: 80vh;
  }

  .containerChildren {
    justify-content: space-evenly;
  }

  .pipe {
    display: none;
  }

  .informations {
    align-items: center;
  }

  .form {
    padding: 0 2rem;
    width: 100%;
    align-items: normal;
    margin-top: 2vh;
  }

  .logo {
    width: 5rem;
  }

  .logos {
    display: none;
  }

  .logosMobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2vh 0;
  }

  .welcome {
    font-size: 18px;
  }

  .firstStep {
    font-size: 15px;
  }

  .captchaContainer>div {
    transform: scale(0.9);
    transform-origin: center;
  }
}